<template>
    <div class="auth">
      <v-snackbar class="white--text text-center headline" top v-model="snackbar" :timeout="3000" :color="notificationType">{{notificationText}}</v-snackbar>

      <img class="d-block" width="300" :src="require('@/assets/logo.png')" alt="Group Trip">
      <h4 class="subheader subheaderGrey--text mt-2 ml-5 text-bold text-uppercase">login with group trip</h4>

      <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      grow
      class="text-bold my-3"
      >
        <v-tab>
          Login as a User
        </v-tab>
        <v-tab>
          Login as an Agency
        </v-tab>
      </v-tabs>

      <v-form v-model="valid" ref="loginForm" class="mt-5 mr-10">
          <div class="form-group">
              <label for="email" class="ml-5 paragraph--text">Email</label>
              <v-text-field height="60" :id="tab ? 'agency-email' : 'user-email'" outlined rounded type="email"
              v-model="credintials.email"
              :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
              >
                  <template v-slot:prepend-inner>
                      <div class="field-item">
                          <v-icon color="forms">mdi-email-outline</v-icon>
                      </div>
                  </template>
              </v-text-field>
          </div>

          <div class="form-group">
              <label for="password" class="ml-5 paragraph--text">Password</label>
              <v-text-field height="60" :id="tab ? 'agency-pass' : 'user-pass'" outlined rounded type="password"
              v-model="credintials.password"
              :rules="[v => !!v || 'Password is required', v => v.length >= 8 || 'Password must be at least 8 characters']"
              >
                  <template v-slot:prepend-inner>
                      <div class="field-item">
                          <v-icon color="forms">mdi-lock-outline</v-icon>
                      </div>
                  </template>
              </v-text-field>
          </div>
          <v-row justify="space-between" align="center" class="mx-3">
              <v-checkbox
              v-model="rememberMe"
              label="Remember Me"
              ></v-checkbox>

        <router-link to="/auth/forgot-password">
          <v-btn text small color="primaryDark">Forgot password ?</v-btn>
        </router-link>
      </v-row>

      <v-btn rounded :loading="loginBtnLoad" color="pink white--text" x-large height="60" @click="login"
             class="my-5 d-block submit">login
      </v-btn>

      <p class="mb-0 text-center paragraph--text">
        Don’t have an account ?
        <router-link to="/auth/register">
          <v-btn text color="primaryDark"> signup now</v-btn>
        </router-link>
      </p>

      <div v-if="!tab">
        <p class="hor-or"><span>Or</span></p>

        <p class="my-5 text-center paragraph--text">
          Continue with social media
        </p>
        <div class="social mx-auto">
          <socialLogin></socialLogin>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import auth from '@/web_services/auth'
import axios from 'axios'
import socialLogin from '../SocialLogin'

export default {
  components: {
    socialLogin
  },
  data () {
    return {
      snackbar: false,
      notificationText: '',
      notificationType: '',
      valid: true,
      fbEmail: '',
      personalID: 0,
      loginBtnLoad: false,
      credintials: {
        email: '',
        password: ''
      },
      rememberMe: false,
      tab: null
    }
  },
  watch: {
    tab (value) {
      this.$store.dispatch('setAuthTab', value)
    }
  },
  methods: {
    // loginWithGoogle () {
    //   this.$gAuth
    //     .signIn()
    //     .then(GoogleUser => {
    //       // on success do something
    //       var userInfo = {
    //         loginType: 'google',
    //         google: {
    //           auth: GoogleUser.getAuthResponse(),
    //           user: {
    //             name: GoogleUser.getBasicProfile().getName(),
    //             email: GoogleUser.getBasicProfile().getEmail(),
    //             profileImage: GoogleUser.getBasicProfile().getImageUrl()
    //           }
    //         }
    //       }
    //       this.$store.commit('setLoginUser', userInfo)
    //     })
    //     .catch(() => {
    //     })
    // },
    // loginWithFacebook () {
    //   window.FB.login(response => {
    //     if (response && response.authResponse) {
    //       var userInfo = {
    //         loginType: 'fb',
    //         fb: {
    //           auth: response.authResponse
    //         }
    //       }
    //       this.$store.commit('setLoginUser', userInfo)
    //       window.FB.api(`/${response.authResponse.userID}`, userResponse => {
    //         if (userResponse) {
    //           var userInfo = {
    //             loginType: 'fb',
    //             fb: {
    //               auth: response.authResponse,
    //               user: userResponse
    //             }
    //           }
    //           this.$store.commit('setLoginUser', userInfo)
    //         }
    //       }, this.params)
    //     }
    //   }, this.params)
    // },
    login () {
      this.$refs.loginForm.validate()
      if (this.valid) {
        this.loginBtnLoad = true
        let endpoint
        if (this.tab === 0) endpoint = auth.userLogin(this.credintials)
        else endpoint = auth.agencyLogin(this.credintials)
        endpoint.then(res => {
          this.$cookies.set('gt_user_token', res.data.data.token, new Date(res.data.data.expire_at))
          this.$cookies.set('gt_user_role', res.data.data.user.role, new Date(res.data.data.expire_at))
          this.$store.dispatch('setUserData', res.data.data.user)
          axios.defaults.headers.common.Authorization = 'Bearer ' + this.$cookies.get('gt_user_token')
          if (res.data.data.user.role === 'Traveler') this.$router.push({ path: '/' })
          else if (res.data.data.user.role === 'Agency') this.$router.push({ path: '/vendors' })
          else this.$router.push({ path: '/' })
        }).catch(error => {
          let text = ''
          if (error.response.data.errors && error.response.data.errors.length) {
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
              value.forEach(item => {
                text += item + ' ! '
              })
            })
          } else text = error.response.data.message
          this.showNotification('error', text)
        }).finally(() => {
          this.loginBtnLoad = false
        })
      } else {
        this.showNotification('warning', 'Please enter your email and your password')
      }
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    },
    facebook () {
      auth.facebook().then(() => {
      })
    }
  },
  created () {
    this.tab = this.$store.state.authTab
  }
}
</script>

<style>
.v-text-field--outlined fieldset {
  border-color: #cecece !important;
}

.field-item {
  padding-right: 15px;
  border-right: 1px solid #cecece;
}
</style>
