<template>
    <div>
        <v-row style="height: 75vh" justify="center" align="center">
            <div class="text-center">
                <v-img v-if="user.profile_photo" :src="user.profile_photo" max-width="300" height="300px" class="mx-auto rounded-circle"></v-img>
                <v-img v-else src="../../assets/images/agency.png" max-width="300" height="300px" class="mx-auto rounded-circle"></v-img>
                <h3 class="text-h4 my-5 text-center">Welcome to your account</h3>
                <h5 class="text-h5 text--subheaderGrey">You can track all of your trips and bookings from one place</h5>
            </div>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user'])
  }
}
</script>
