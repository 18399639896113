<template>
  <div class="signup-buttons">
    <div id="fb-root"></div>
    <v-btn @click="loginWithFacebook()" elevation="2" class="mx-2" fab x-small color="white">
      <v-icon color="#004C7C">mdi-facebook</v-icon>
    </v-btn>
    <v-btn @click.prevent="loginWithGoogle" elevation="2" class="mx-2" fab x-small color="white">
      <v-icon color="#BF1111">mdi-google-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import auth from '../web_services/auth'
import axios from 'axios'

export default {
  data () {
    return {
      loggedIn: null
    }
  },
  name: 'login_signup_social',
  methods: {
    loginWithGoogle () {
      const vm = this
      this.$gAuth
        .signIn()
        .then(GoogleUser => {
          var userInfo = {
            name: GoogleUser.getBasicProfile().getName(),
            email: GoogleUser.getBasicProfile().getEmail(),
            profileImage: GoogleUser.getBasicProfile().getImageUrl(),
            id: GoogleUser.getId()
          }
          auth.google(userInfo).then(res => {
            vm.$cookies.set('gt_user_token', res.data.data.token, new Date(res.data.data.expire_at))
            vm.$store.dispatch('setUserData', res.data.data.user)
            axios.defaults.headers.common.Authorization = 'Bearer ' + this.$cookies.get('gt_user_token')
            vm.$router.push({ path: '/' })
          })
        })
        .catch(() => {
        })
    },
    loginWithFacebook () {
      window.FB.login(response => {
        if (response && response.authResponse) {
          const accessToken = response.authResponse.accessToken
          const vm = this
          window.FB.api('/me', 'get', {
            access_token: accessToken,
            fields: 'id,name,gender,email,location,friends,likes,picture'
          }, function (response) {
            auth.facebook(response).then(res => {
              vm.$cookies.set('gt_user_token', res.data.data.token, new Date(res.data.data.expire_at))
              vm.$store.dispatch('setUserData', res.data.data.user)
              axios.defaults.headers.common.Authorization = 'Bearer ' + this.$cookies.get('gt_user_token')
              vm.$router.push({ path: '/' })
            })
          })
        }
      }, this.params, { scope: 'email' })
    }
  }
}
</script>
